import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import { Field } from 'react-final-form';
import Spinner from '@shoreag/base/Spinner';
import parseUuid from '@shoreag/helpers/parse-uuid';
import { useQuery } from 'react-apollo';
import { get } from 'lodash';
import allRecursiveSchemaDatumQuery from '../../graphql/queries/all-recursive-schema-datum.gql';

const SchemaColumnDropdown = ({
  formName,
  wrapperSx,
  selected,
  onDatumSelect,
  schemaIdVersion,
  label,
}) => {
  const {
    data: allRecursiveSchemaDatumData,
    loading: isRecursiveLoading,
  } = useQuery(allRecursiveSchemaDatumQuery, {
    skip: !schemaIdVersion,
    variables: { id: schemaIdVersion },
  });

  const fieldSortFunction = (a, b) =>
    a.fieldName.toLowerCase() > b.fieldName.toLowerCase() ? 1 : -1;

  const fuzzyUuidMatch = (id1, id2) =>
    parseUuid(id1).id === parseUuid(id2).id &&
    Number(parseUuid(id1).version) === Number(parseUuid(id2).version);

  const recursivelyMapDatums = (datums, schemaId, pathPrefix = '') =>
    datums.reduce(
      (fieldNames, datum) =>
        fuzzyUuidMatch(datum.schemaVersionId, schemaId)
          ? fieldNames.concat(
              datum.definitionType === 'object'
                ? recursivelyMapDatums(
                    datums,
                    datum.nestedSchemaWithVersion.id,
                    `${pathPrefix}${datum.fieldName}${
                      datum.multiple ? '[]' : ''
                    }.`
                  )
                : [
                    {
                      definitionType: datum.definitionType,
                      fieldName: `${pathPrefix}${datum.fieldName}`,
                      id: datum.id,
                    },
                  ]
            )
          : fieldNames,
      []
    );

  const mappedData = useMemo(() => {
    if (!allRecursiveSchemaDatumData) return [];
    const allRecursiveSchemaDatum = get(
      allRecursiveSchemaDatumData,
      'allRecursiveSchemaDatum',
      []
    );
    return recursivelyMapDatums(allRecursiveSchemaDatum, schemaIdVersion)
      .filter((i) => !selected.includes(i.fieldName))
      .sort(fieldSortFunction);
  }, [allRecursiveSchemaDatumData, schemaIdVersion, selected]);

  let content = null;

  if (isRecursiveLoading) {
    content = <Spinner mt={5} py="sm" />;
  }

  if (mappedData.length > 0) {
    content = (
      <Field
        component={Dropdown}
        label={label}
        name={formName}
        onChange={(value) => {
          onDatumSelect({ fieldName: value });
        }}
        options={mappedData.map((item) => {
          const label = /[^.]*$/.exec(item.fieldName)[0];
          return {
            label: (
              <span title={item.fieldName}>
                <Box
                  sx={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 1,
                  }}
                >
                  <Box
                    sx={{
                      flex: 'auto',
                      mr: 2,
                      wordBreak: 'break-all',
                    }}
                  >
                    {label}
                  </Box>
                </Box>
                <Box
                  sx={{
                    color: 'grays.4',
                    fontSize: 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.fieldName}
                </Box>
              </span>
            ),
            value: item.fieldName,
          };
        })}
        placeholder="Search..."
        wrapperSx={{ ...wrapperSx }}
      />
    );
  }

  return content;
};

SchemaColumnDropdown.propTypes = {
  formName: PropTypes.string.isRequired,
  label: PropTypes.string,
  onDatumSelect: PropTypes.func,
  schemaIdVersion: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  wrapperSx: PropTypes.shape({}),
};

SchemaColumnDropdown.defaultProps = {
  label: 'Schema columns',
  onDatumSelect: () => null,
  selected: [],
  wrapperSx: {},
};

export default SchemaColumnDropdown;
