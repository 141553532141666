import React from 'react';
import { get } from 'lodash';
import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import { camelCase } from 'change-case';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import Dropdown from '@shoreag/base/Dropdown';
import Input from '@shoreag/base/Input';
import ToggleSwitch from '@shoreag/base/ToggleSwitch';
import { required } from '@shoreag/validations';
import RemoveButton from '../../../RemoveButton';
import AddButton from '../../../AddButton';
import {
  DATE_FORMAT_OPTIONS,
  FILENAME_TYPE_OPTIONS,
  FILENAME_TYPES,
} from '../../../../utilities/constants';
import SchemaColumnDropdown from '../../../SchemaColumnDropdown';
import SchemaTreeColumnInput from '../../../SchemaTreeColumnInput';

const FileNameStandardGenerator = ({
  addButtonLabel,
  formName,
  formContext,
  maxLimit,
  allowDefaultOne,
  schemaIDWithVersion,
}) => {
  if (allowDefaultOne && !get(formContext.values, formName)) {
    formContext.form.change(formName, [{}]);
  }
  const wrapperStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '33.33%'],
    mb: 6,
    mt: 0,
    px: 4,
    width: '100%',
  };

  return (
    <Box sx={{ mb: 5, mt: 5, width: '100%' }}>
      <FieldArray name={formName}>
        {({ fields }) => {
          const totalFields = fields.length;
          return (
            <>
              {fields.map((name, index) => {
                const type = get(formContext.values, `${name}.type`, '');
                const isSchemaTree = get(
                  formContext.values,
                  `${name}.schemaTree`,
                  false
                );
                return (
                  <Box
                    key={`${name}-${index}`}
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Field
                      component={Dropdown}
                      data-cy={`${name}.type`}
                      defaultValue={type || FILENAME_TYPES.STRING}
                      label="Type *"
                      name={`${name}.type`}
                      options={FILENAME_TYPE_OPTIONS}
                      validate={required}
                      wrapperSx={{
                        ...wrapperStyled,
                        maxWidth: '25%',
                      }}
                    />
                    {type === FILENAME_TYPES.SCHEMA_COLUMNS && (
                      <>
                        {isSchemaTree && (
                          <SchemaTreeColumnInput
                            formContext={formContext}
                            formName={`${name}.value`}
                            label="Schema column *"
                            outerSx={{
                              ...wrapperStyled,
                              maxWidth: 'calc(100% - 180px)',
                            }}
                            placeholder="Select"
                            schemaIdVersion={schemaIDWithVersion}
                            validate={required}
                          />
                        )}
                        {!isSchemaTree && (
                          <SchemaColumnDropdown
                            formContext={formContext}
                            formName={`${name}.value`}
                            label="Schema column *"
                            schemaIdVersion={schemaIDWithVersion}
                            validate={required}
                            wrapperSx={{
                              ...wrapperStyled,
                              maxWidth: 'calc(100% - 180px)',
                            }}
                          />
                        )}
                        <Box
                          sx={{
                            ...wrapperStyled,
                            display: 'flex',
                            height: '3rem',
                            justifyContent: 'space-between',
                            maxWidth: '180px',
                            ml: '-0.7rem',
                            mt: '1.2rem',
                            pl: '0',
                          }}
                        >
                          <Field
                            component={ToggleSwitch}
                            data-cy={`${name}.schemaTree`}
                            errorSx={{
                              ml: 4,
                              mt: 0,
                            }}
                            label="Schema tree"
                            labelSx={{
                              ml: 0,
                              mr: 1,
                              order: 'unset',
                            }}
                            name={`${name}.schemaTree`}
                            type="checkbox"
                            wrapperSx={{
                              alignItems: 'center',
                              borderColor: 'grays.2',
                              borderRadius: 1,
                              borderStyle: 'solid',
                              borderWidth: '1px',
                              display: 'flex',
                              height: '2.95rem',
                              justifyContent: 'space-between',
                              px: 4,
                            }}
                          />
                        </Box>
                      </>
                    )}
                    {type === FILENAME_TYPES.STRING && (
                      <Field
                        component={Input}
                        data-cy={`${name}.value`}
                        label="String *"
                        name={`${name}.value`}
                        validate={required}
                        wrapperSx={{
                          ...wrapperStyled,
                          maxWidth: '100%',
                        }}
                      />
                    )}
                    {type === FILENAME_TYPES.PREDEFINED_DATE_FORMAT && (
                      <Field
                        component={Dropdown}
                        data-cy={`${name}.value`}
                        // isClearable
                        label="Select Date Format *"
                        name={`${name}.value`}
                        options={DATE_FORMAT_OPTIONS}
                        validate={required}
                        wrapperSx={{
                          ...wrapperStyled,
                          maxWidth: '100%',
                        }}
                      />
                    )}
                    <Field
                      component={Input}
                      data-cy={`${name}.joiner`}
                      label="Joiner"
                      name={`${name}.joiner`}
                      // validate={required}
                      wrapperSx={{
                        ...wrapperStyled,
                        maxWidth: '180px',
                      }}
                    />
                    {!(allowDefaultOne && totalFields === 1) ? (
                      <RemoveButton
                        onClick={() => fields.remove(index)}
                        sx={{
                          alignSelf: 'start',
                          flex: 'none',
                          ml: 0,
                          mt: 6,
                          px: 4,
                        }}
                      >
                        Remove
                      </RemoveButton>
                    ) : null}
                  </Box>
                );
              })}
              {(!maxLimit || totalFields < maxLimit) && (
                <AddButton
                  {...(addButtonLabel
                    ? {
                        'data-cy': camelCase(addButtonLabel),
                        label: addButtonLabel,
                      }
                    : {})}
                  onClick={() =>
                    fields.push({
                      type: FILENAME_TYPES.STRING,
                    })
                  }
                  sx={{ height: '2rem', width: '2rem' }}
                  wrapperSx={{ justifyContent: 'flex-end', mt: 0, px: 4 }}
                />
              )}
            </>
          );
        }}
      </FieldArray>
    </Box>
  );
};

FileNameStandardGenerator.propTypes = {
  addButtonLabel: PropTypes.string,
  allowDefaultOne: PropTypes.bool,
  divider: PropTypes.bool,
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }),
  formName: PropTypes.string.isRequired,
  maxLimit: PropTypes.number,
  schemaIDWithVersion: PropTypes.string.isRequired,
};

FileNameStandardGenerator.defaultProps = {
  addButtonLabel: '',
  allowDefaultOne: false,
  divider: true,
  formContext: {},
  maxLimit: null,
};

export default FileNameStandardGenerator;
