import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import { ThemeContext } from 'styled-components';
import { required } from '@shoreag/validations';
import Input from '@shoreag/base/Input';
import { FieldArray } from 'react-final-form-arrays';
import Card from '@shoreag/base/Card';
import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import get from 'lodash/get';
import RemoveButton from '../../RemoveButton';
import AddButton from '../../AddButton';
import FlagHeading from '../../FlagHeading';
import getFileFormats from '../../../utilities/get-file-formats';
import { FILE_TYPE_OPTIONS } from '../../../utilities/constants';
import SchemaDropdown from '../../SchemaDropdown';
import PillsGroup from '../../PillsGroup';
import FileNameStandardGenerator from './FileNameStandardGenerator';

const FileSetupForm = ({
  children,
  formContext,
  formName,
  maxLimit,
  allowDefaultOne,
  ...sx
}) => {
  const {
    defaultStyles: { fieldLabel },
  } = useContext(ThemeContext);

  if (allowDefaultOne && !get(formContext.values, formName)) {
    formContext.form.change(formName, [{}]);
  }
  const wrapperStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '50%', '50%'],
    mb: 6,
    mt: 0,
    px: 4,
    width: '100%',
  };

  return (
    <Box sx={sx}>
      <FieldArray name={formName}>
        {({ fields }) => {
          const totalFields = fields.length;
          return (
            <>
              <Box as="h2" sx={{ mb: 4, mt: 6 }}>
                File Setup
              </Box>
              {totalFields > 0 ? (
                <>
                  {fields.map((name, index) => {
                    const fileFormat = get(
                      formContext.values,
                      `${name}.fileFormat`
                    );
                    // const fileIdentifier =
                    //   get(formContext.values, `${name}.fileIdentifier`, []) ||
                    //   [];
                    // const filenameType = get(
                    //   formContext.values,
                    //   `${name}.filenameType`,
                    //   ''
                    // );

                    const schemaId = get(
                      formContext.values,
                      `${name}.schemaId`,
                      ''
                    );
                    const versionId = get(
                      formContext.values,
                      `${name}.versionId`,
                      ''
                    );
                    const schemaIDWithVersion =
                      schemaId && versionId && [schemaId, versionId].join(':');

                    const filenameStandard = get(
                      formContext.values,
                      `${name}.filenameStandard`,
                      []
                    );
                    const updatedFilenameStandard = [];
                    if (filenameStandard?.length > 0) {
                      filenameStandard.forEach((i) => {
                        updatedFilenameStandard.push(i.value);
                        if (i.value) {
                          updatedFilenameStandard.push(i.joiner);
                        }
                      });
                    }

                    return (
                      <Card
                        key={`${name}-${index}`}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          mb: 5,
                          pb: 0,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            mx: -4,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              mb: 5,
                              px: 4,
                              width: '100%',
                            }}
                          >
                            <FlagHeading color="accent" sx={{ mt: 0 }}>
                              File Setup
                              <Box
                                bg="accent"
                                borderRadius={0}
                                color="white"
                                ml={3}
                                px={2}
                                py="2px"
                              >
                                {index + 1}
                              </Box>
                            </FlagHeading>
                            {!(allowDefaultOne && totalFields === 1) ? (
                              <RemoveButton
                                onClick={() => fields.remove(index)}
                              >
                                Remove
                              </RemoveButton>
                            ) : null}
                          </Box>
                          <Field
                            component={Dropdown}
                            data-cy={`${name}.fileFormat`}
                            label="File Format *"
                            name={`${name}.fileFormat`}
                            options={getFileFormats()}
                            validate={required}
                            wrapperSx={{
                              ...wrapperStyled,
                              maxWidth: ['100%', null, null, '50%', '25%'],
                              width: 'auto',
                            }}
                          />
                          {fileFormat === 'xml' && (
                            <Field
                              component={Input}
                              data-cy={`${name}.path`}
                              label="XML Path *"
                              name={`${name}.path`}
                              validate={required}
                              wrapperSx={{
                                ...wrapperStyled,
                                maxWidth: ['100%', null, null, '50%', '50%'],
                              }}
                            />
                          )}
                          {fileFormat === 'json' && (
                            <Field
                              component={Input}
                              data-cy={`${name}.path`}
                              label="JSON Path *"
                              name={`${name}.path`}
                              validate={required}
                              wrapperSx={{
                                ...wrapperStyled,
                                maxWidth: ['100%', null, null, '50%', '50%'],
                              }}
                            />
                          )}
                          {fileFormat === 'txt' && (
                            <Field
                              component={Input}
                              data-cy={`${name}.path`}
                              label="Delimiter *"
                              name={`${name}.path`}
                              validate={required}
                              wrapperSx={{
                                ...wrapperStyled,
                                maxWidth: ['100%', null, null, '50%', '50%'],
                              }}
                            />
                          )}
                          <Field
                            component={Dropdown}
                            data-cy={`${name}.fileType`}
                            label="File Type *"
                            name={`${name}.fileType`}
                            options={FILE_TYPE_OPTIONS}
                            validate={required}
                            wrapperSx={{
                              ...wrapperStyled,
                              maxWidth: ['100%', null, null, '50%', '25%'],
                            }}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              width: '100%',
                            }}
                          >
                            <SchemaDropdown
                              filedProps={{
                                wrapperSx: {
                                  ...wrapperStyled,
                                  flex: 'none',
                                  maxWidth: '50%',
                                },
                              }}
                              formContext={formContext}
                              formName={name}
                              hideSpinner
                              isRequired
                            />
                          </Box>
                        </Box>
                        {schemaIDWithVersion && (
                          <>
                            <Box as="label" sx={{ ...fieldLabel, mb: 4 }}>
                              Filename standard format *
                            </Box>
                            <Box
                              sx={{
                                borderColor: 'grays.2',
                                borderRadius: 1,
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                mb: 6,
                                pt: 4,
                                px: 2,
                                width: '100%',
                              }}
                            >
                              {updatedFilenameStandard?.length > 0 &&
                                filenameStandard[0].value && (
                                  <>
                                    <PillsGroup
                                      data={[...updatedFilenameStandard]
                                        .filter((i) => i)
                                        .map((i) => ({
                                          label: i,
                                        }))}
                                      pillSx={{
                                        ':hover': {
                                          bg: 'white',
                                          color: 'primary',
                                        },
                                        bg: 'white',
                                        borderColor: 'border',
                                        borderStyle: 'solid',
                                        borderWidth: '1px',
                                        boxShadow: 'none',
                                        color: 'primary',
                                      }}
                                      viewOnly
                                      wrapperSx={{
                                        pb: 4,
                                        pt: 4,
                                        px: 4,
                                        width: '100%',
                                      }}
                                    />
                                    <hr />
                                  </>
                                )}
                              <FileNameStandardGenerator
                                addButtonLabel="Add row"
                                allowDefaultOne
                                formContext={formContext}
                                formName={`${name}.filenameStandard`}
                                schemaIDWithVersion={schemaIDWithVersion}
                              />
                            </Box>
                          </>
                        )}
                      </Card>
                    );
                  })}
                  {(!maxLimit || totalFields < maxLimit) && (
                    <AddButton
                      data-cy="addFileSetup"
                      label="Add File setup"
                      onClick={() => fields.push({})}
                      wrapperSx={{ mt: 0 }}
                    />
                  )}
                </>
              ) : (
                <Card sx={{ display: 'flex', justifyContent: 'center' }}>
                  <AddButton
                    data-cy="addFileSetup"
                    label="Add File setup"
                    onClick={() => fields.push({})}
                  />
                </Card>
              )}
            </>
          );
        }}
      </FieldArray>
    </Box>
  );
};

FileSetupForm.propTypes = {
  allowDefaultOne: PropTypes.bool,
  children: PropTypes.node,
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }),
  formName: PropTypes.string.isRequired,
  maxLimit: PropTypes.number,
};

FileSetupForm.defaultProps = {
  allowDefaultOne: false,
  children: null,
  formContext: {},
  maxLimit: null,
};

export default FileSetupForm;
