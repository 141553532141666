import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Spinner from '@shoreag/base/Spinner';
import parseUuid from '@shoreag/helpers/parse-uuid';
import { useQuery } from 'react-apollo';
import { get } from 'lodash';
import allRecursiveSchemaDatumQuery from '../../../graphql/queries/all-recursive-schema-datum.gql';
import MappingValuesCard from '../../MappingValuesCard';

const SchemaTreeColumnSelector = ({
  formName,
  wrapperSx,
  selected,
  onDatumSelect,
  onSchemaSelect,
  parentSchemaPath,
  schemaIdVersion,
  showSearch,
}) => {
  const {
    data: allRecursiveSchemaDatumData,
    loading: isRecursiveLoading,
  } = useQuery(allRecursiveSchemaDatumQuery, {
    skip: !schemaIdVersion,
    variables: { id: schemaIdVersion },
  });

  const fieldSortFunction = (a, b) =>
    a.fieldName.toLowerCase() > b.fieldName.toLowerCase() ? 1 : -1;

  const fuzzyUuidMatch = (id1, id2) =>
    parseUuid(id1).id === parseUuid(id2).id &&
    Number(parseUuid(id1).version) === Number(parseUuid(id2).version);

  const recursivelyMapDatums = (datums, schemaId, pathPrefix = '') =>
    datums.reduce(
      (fieldNames, datum) =>
        fuzzyUuidMatch(datum.schemaVersionId, schemaId)
          ? fieldNames.concat(
              datum.definitionType === 'object'
                ? recursivelyMapDatums(
                    datums,
                    datum.nestedSchemaWithVersion.id,
                    `${pathPrefix}${datum.fieldName}${
                      datum.multiple ? '[]' : ''
                    }.`
                  )
                : [
                    {
                      definitionType: datum.definitionType,
                      fieldName: `${pathPrefix}${datum.fieldName}`,
                      id: datum.id,
                    },
                  ]
            )
          : fieldNames,
      []
    );

  const mappedData = useMemo(() => {
    if (!allRecursiveSchemaDatumData) return [];
    const allRecursiveSchemaDatum = get(
      allRecursiveSchemaDatumData,
      'allRecursiveSchemaDatum',
      []
    );
    return recursivelyMapDatums(allRecursiveSchemaDatum, schemaIdVersion)
      .filter((i) => !selected.includes(i.fieldName))
      .sort(fieldSortFunction);
  }, [allRecursiveSchemaDatumData, schemaIdVersion, selected]);

  let content = null;

  if (isRecursiveLoading) {
    content = <Spinner py="sm" />;
  }
  if (mappedData.length > 0) {
    content = (
      <MappingValuesCard
        data={mappedData}
        disabledDatums={selected}
        emptyMessage="All Columns are selected"
        onDatumSelect={({ fieldName }) => {
          onDatumSelect(fieldName);
        }}
        onSchemaSelect={(schema) => {
          onSchemaSelect(schema);
        }}
        searchName={`${formName}.searchSchema`}
        selectedDataPath={parentSchemaPath}
        showSearch={showSearch}
        sx={wrapperSx}
      />
    );
  }

  return content;
};

SchemaTreeColumnSelector.propTypes = {
  formName: PropTypes.string.isRequired,
  onDatumSelect: PropTypes.func,
  onSchemaSelect: PropTypes.func,
  parentSchemaPath: PropTypes.string,
  schemaIdVersion: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  showSearch: PropTypes.bool,
  wrapperSx: PropTypes.shape({}),
};

SchemaTreeColumnSelector.defaultProps = {
  onDatumSelect: () => null,
  onSchemaSelect: () => null,
  parentSchemaPath: '',
  selected: [],
  showSearch: false,
  wrapperSx: {},
};

export default SchemaTreeColumnSelector;
