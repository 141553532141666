import React, { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Input from '@shoreag/base/Input';
import Box from '@shoreag/base/Box';
import Icon from '../Icon';
import SchemaTreeColumnSelector from './SchemaTreeColumnSelector';

const SchemaTreeColumnInput = ({
  formContext,
  formName,
  wrapperSx,
  outerSx,
  schemaIdVersion,
  label,
  showSearch,
  ...rest
}) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [parentSchema, setParentSchema] = useState(null);
  const theme = useContext(ThemeContext);
  const componentRef = useRef(null);

  const focusColor = theme.colors.primary;

  const manuStyle = {
    boxShadow: 1,
    left: 0,
    mt: 1,
    p: 0,
    position: 'absolute',
    top: '100%',
    width: '100%',
    zIndex: 1,
  };

  const handleOutsideClick = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setToggleMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <Box ref={componentRef} sx={outerSx}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', position: 'relative', width: '100%' }}>
          <Field
            component={Input}
            data-cy={formName}
            label={label}
            name={formName}
            sx={{
              borderColor: toggleMenu ? focusColor : '',
              pr: '2.75rem',
            }}
            wrapperSx={{ flex: 'auto', ...wrapperSx }}
            {...rest}
          />
          <Box
            onClick={() => setToggleMenu(!toggleMenu)}
            sx={{
              alignItems: 'center',
              cursor: 'pointer',
              display: 'flex',
              flex: 'none',
              height: '3rem',
              justifyContent: 'center',
              mt: label ? '1.25rem' : '',
              position: 'absolute',
              right: 0,
            }}
          >
            <Box
              sx={{
                borderLeftColor: 'grays.3',
                borderLeftStyle: 'solid',
                borderLeftWidth: '1px',
                px: '1rem',
                width: '100%',
              }}
            >
              <Icon
                svg="chevron"
                sx={{
                  alignItems: 'center',
                  color: toggleMenu ? focusColor : 'grays.3',
                  display: 'flex',
                  height: '20px',
                  justifyContent: 'center',
                  transform: `rotate(${toggleMenu ? '0' : '180'}deg)`,
                  width: '0.78rem',
                }}
              />
            </Box>
          </Box>
        </Box>
        {toggleMenu && (
          <SchemaTreeColumnSelector
            formContext={formContext}
            formName={formName}
            onDatumSelect={(fieldName) => {
              const fieldNameStr = fieldName;
              const parentpath = fieldNameStr.substring(
                0,
                fieldNameStr.lastIndexOf('.')
              );
              setParentSchema(parentpath);
              formContext.form.change(formName, fieldName);
            }}
            onSchemaSelect={(schema) => {
              setParentSchema(schema?.path);
            }}
            parentSchemaPath={parentSchema}
            schemaIdVersion={schemaIdVersion}
            showSearch={showSearch}
            wrapperSx={manuStyle}
          />
        )}
      </Box>
    </Box>
  );
};

SchemaTreeColumnInput.propTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }),
  formName: PropTypes.string.isRequired,
  label: PropTypes.string,
  outerSx: PropTypes.shape({}),
  schemaIdVersion: PropTypes.string.isRequired,
  showSearch: PropTypes.bool,
  wrapperSx: PropTypes.shape({}),
};

SchemaTreeColumnInput.defaultProps = {
  formContext: {},
  label: '',
  outerSx: {},
  showSearch: false,
  wrapperSx: {},
};

export default SchemaTreeColumnInput;
